body {
}

code {
}

.page-content {
    background-color: #000;
    background-image: none;
    padding: 0 !important;
    padding-top: 90px !important;
    margin: 0 !important;
}

.home-btn-primary {
    color: #fff;
    background-color: #bb332c !important;
    border-color: #bb332c !important;
}
.home-btn-outline-primary {
    background-color: #bb332c !important;
    border-color: #bb332c !important;
}

.main-modules-header-primary-text {
    color: #bb332c;
    line-height: 3rem;
}

.home-card-header {
    background-color: #FFF !important;
    border-bottom: solid 5px #000 !important;
}

.home-card-body {
    padding: 0!important;
}

.accordion-item {
    padding-left: 60px;
    background-color: #bb332cff !important;
}

.accordion-item:nth-child(1) {
    background-color: #bb332cff !important;
}

.accordion-item:nth-child(2) {
    background-color: #bb332cdd !important;
}

.accordion-item:nth-child(3) {
    background-color: #bb332cbb !important;
}

.accordion-item:nth-child(4) {
    background-color: #bb332c99 !important;
}

.accordion-item:nth-child(5) {
    background-color: #bb332c77 !important;
}

.accordion-header > button > svg {
    margin-left: -60px;
}

.accordion-body {
    background-color: #FFF;
}

.accordion-button:not(.collapsed) {
    color: #fff !important;
    background-color: #bb332c55 !important;
}

.th-light {
    background-image: linear-gradient(to bottom right, #bb332c88, #bb332caa);
    border: solid 3px #fff;
}

.th-dark {
    background-image: linear-gradient(to bottom right, #bb332cff, #bb332caa);
    border: solid 3px #fff;
    color: #fff;
}

.tr-total {
    border: solid 3px #bb332cff;
}